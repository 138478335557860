<template>
  <div>
    <div class="container">
      <Breadcrumb
        :breadcrumbList="[ { 'name': 'ADMIN DASHBOARD ', 'link': '/admin' },{ 'name': 'TASKS', 'link': '/admin/tasks' },{ 'name': this.task_status[taskStatus].toUpperCase() } ]"/>
      <div class="main-container">
        <h1 class="font-weight-bold">Manage Tasks - {{ this.task_status[taskStatus] }}</h1>

        <div v-if="processed" class="alert alert-success">
          <i class="fa-solid fa-check"></i>
          The task has been successfully processed.
        </div>

        <div class="col-xl-12 content-box">
          <div class="row row-featured row-featured-category">
            <div class="col-xl-12 box-title border-bottom">
              <div class="inner">
                <h2>Search for a Task or Username</h2>
              </div>
            </div>
            <div class="container mb-3 mt-3">
              <b-input-group class="w-100">
                <b-form-input class="nav-searchbar" v-model="searchInput" ref="searchInput" name="searchTaskInputBox" :placeholder="$t('find_service')"></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" size="sm" type="submit" id="ordersSearchButton" @click="getAdminTasks(1)">
                    <div class="d-flex justify-content-center align-items-center px-3">
                      <i class="fa-solid fa-magnifying-glass mr-2"></i>
                      <span class="pl-1 h6 m-0 font-weight-normal">Search</span>
                    </div>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div :id="'taskUpdateSuccess'" style="display:none;">
          <b-alert variant="success" show>
            Task has been updated
          </b-alert>
        </div>
        <div v-if="!LoadingPage">
          <div class="container" v-if="showNoResults">
            <b-alert v-model="showNoResults" variant="success" dismissible>
              No tasks found
            </b-alert>
          </div>

          <div class="col-xl-12 content-box">
            <div class="container" v-if="tasks.length > 0">
              <div class="col-xl-12 content-box">
                <div class="row row-featured row-featured-category">
                  <div class="col-xl-12  box-title border-bottom ">
                    <div class="inner"><h2>{{ paginationTitle() }}</h2></div>
                  </div>
                  <AdminPendingTaskTable :tasks="tasks" v-if="this.taskStatus === 'pending'"/>
                  <AdminDeclinedTaskTable :tasks="tasks" v-else-if="this.taskStatus === 'declined'"/>
                  <AdminAcceptedTaskTable :tasks="tasks" v-else-if="this.taskStatus === 'accepted'"/>
                  <AdminWeightingTaskTable :tasks="tasks" v-else-if="this.taskStatus === 'weighting'"/>
                  <AdminBadgesTaskTable :tasks="tasks" v-else-if="this.taskStatus === 'badges'"/>
                  <AdminUnlistedTaskTable :tasks="tasks" v-else-if="this.taskStatus === 'unlisted'"/>
                </div>
              </div>
              <Pagination :perPage="tasksPerPage" :currentPage="currentPage" :total="totalTasks" v-on:paginationUpdate="updatePagination" v-if="totalTasks > tasksPerPage"
                          class="mt-3"/>
            </div>
          </div>
        </div>
        <div v-else>
          <b-spinner class="mt-5 mb-2 mb-md-4" large label="Large Spinner"/>
          <div class="mb-5">{{ $t('loading_tasks') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../apiService.js'
import Pagination from '../../../components/Global/Pagination'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import AdminAcceptedTaskTable from '../../../components/Admin/Task/AdminAcceptedTaskTable'
import AdminDeclinedTaskTable from '../../../components/Admin/Task/AdminDeclinedTaskTable'
import AdminPendingTaskTable from '../../../components/Admin/Task/AdminPendingTaskTable'
import AdminWeightingTaskTable from '../../../components/Admin/Task/AdminWeightingTaskTable'
import AdminBadgesTaskTable from '../../../components/Admin/Task/AdminBadgesTaskTable'
import AdminUnlistedTaskTable from '../../../components/Admin/Task/AdminUnlistedTaskTable'

export default {
  name: 'AdminTaskLists',
  components: {
    AdminUnlistedTaskTable,
    AdminBadgesTaskTable,
    Pagination,
    AdminAcceptedTaskTable,
    AdminDeclinedTaskTable,
    AdminPendingTaskTable,
    Breadcrumb,
    AdminWeightingTaskTable
  },
  data () {
    return {
      LoadingPage: true,
      tasks: [],
      currentPage: 1,
      tasksPerPage: 25,
      totalTasks: 0,
      sortAdmin: '',
      showNoResults: false,
      taskStatus: this.$route.params.status,
      searchInput: '',
      task_status: {
        accepted: 'Accepted Tasks',
        declined: 'Declined Tasks',
        pending: 'Pending Tasks',
        weighting: 'Weighting',
        badges: 'Task Badges',
        unlisted: 'Unlisted Tasks'
      },
      processed: false
    }
  },
  mounted () {
    this.getAdminTasks()
    this.processed = location.hash === '#processed'
  },
  methods: {
    paginationTitle () {
      const currentTotalPosition = this.tasksPerPage * (this.currentPage - 1)
      return this.$t('showing_tasks', { page_low_range: currentTotalPosition + 1, page_total: this.tasks.length + currentTotalPosition, total: this.totalTasks })
    },
    getAdminTasks (page = null) {
      if (page !== null) {
        this.currentPage = page
      }

      this.showNoResults = false
      this.LoadingPage = true

      let status
      let hiddenWeighting = false
      let badges = 0

      switch (this.taskStatus) {
        case 'unlisted':
          status = 4
          break
        case 'declined':
          status = 3
          break
        case 'weighting':
          status = 1
          hiddenWeighting = true
          break
        case 'badges':
          status = 1
          badges = 1
          break
        case 'pending':
          status = 2
          break
        case 'accepted':
        default:
          status = 1
      }

      ApiService.getTasksAdmin(this.tasksPerPage, this.currentPage, status, this.searchInput, true, 1, hiddenWeighting, badges).then(response => {
        this.tasks = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.tasksPerPage = response.data.data.pagination.per_page
        this.totalTasks = response.data.data.pagination.total
        this.LoadingPage = false
        if (this.tasks.length === 0) {
          this.showNoResults = true
        }
      }).catch(() => {
      })
    },
    updatePagination (page = this.currentPage) {
      this.currentPage = page
      this.getAdminTasks()
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100% !important;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  float: left;
  margin-right: 20px;
}

.breadcrumbs a:link,
.breadcrumbs a:visited,
.breadcrumbs a:hover,
.breadcrumbs a:active {
  text-decoration: none;
  color: #888;
}

.breadcrumbs:hover,
.breadcrumbs a {
  cursor: default;
}

.breadcrumbs a.clickable:hover {
  cursor: pointer;
  color: #000 !important;
  text-decoration: underline !important;
}

.breadcrumbs {
  z-index: 3;
  color: #888 !important;
}

.breadcrumbs i {
  color: #888;
}

#search {
  width: 100% !important;
}

.cursor {
  cursor: pointer;
}
</style>
