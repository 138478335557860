<template>
  <div class="container mb-3 mt-3">
    <p class="text-right small">*Response rate and Delivery rate is in the last 30 days</p>
    <table class="table table-striped table-bordered add-manage-table"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th>Username</th>
        <th>Task Title</th>
        <th>Date Created</th>
        <th>Date Modified</th>
        <th>Orders Accepted</th>
        <th>Rating/Reviews</th>
        <th>Dispute in progress</th>
        <th>Disputes in favour of buyer</th>
        <th>Response Rate</th>
        <th>Delivery Rate</th>
        <th>Weighting</th>
        <th v-if="roleCheckEdit">Action</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(task, index) in tasks" :key="index" class="cursor-pointer">
        <td @click="$router.push('/' + task[0].user.username)" data-title="Username" class="d-none d-sm-table-cell">
          <i class="fa-solid fa-magnifying-glass mr-2"/>{{ task[0].user.username }}
        </td>
        <td @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Task Title">{{ task[0].title }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Date Created">{{ date(task[0].date_created) }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Date Modified">{{ date(task[0].date_modified) }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Accepted Orders / Total Orders">{{ task.orders_accepted > 0 && task.orders_total > 0 ? (task.orders_accepted / task.orders_total * 100).toFixed(2) + '%' : 'No orders' }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Rating">{{task[0].review_average}} / {{task[0].review_count}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Dispute in progress">{{task.orders_dispute_current}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Disputes in favour of buyer">{{task.dispute_against}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Response Rate">{{task.response_rate !== null && task.response_rate !== undefined  ? task.response_rate.toFixed(2) + '%' : 'No orders' }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Delivery Rate">{{task.delivery_rate !== null && task.delivery_rate !== undefined ? task.delivery_rate.toFixed(2) + '%' : 'No orders'}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Weighting">{{task[0].hidden_weighting }}</td>
        <td v-if="roleCheckEdit">
          <b-button class="mb-3 w-100" variant="primary"  @click="$router.push('/admin/tasks/' + task[0].user.username + '/' + task[0].slug + '/edit')">Edit Task</b-button>
          <b-button class=" w-100" variant="danger"  @click="removeHiddenWeighting(task[0].user.username, task[0].slug)">Remove weighting</b-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiService from '../../../apiService'

export default {
  name: 'AdminHiddenWeightingTaskTable',
  props: {
    tasks: Array
  },
  data () {
    return {
      selectedHash: '',
      roleCheckEdit: this.$store.getters.hasRole('ROLE_ADMIN_TASK_LIVE_EDIT')
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'Currently not modified'
      }
      const newDate = new Date(date)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    },
    removeHiddenWeighting (username, slug) {
      let vm = this
      this.$swal({
        title: 'Remove weighting',
        text: 'This will remove weighting and task will no longer be boosted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            let uploadData = {
              'weighting': 1,
              'status': 1
            }
            return apiService.editTask(slug, uploadData, username).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: 'Success',
            text: 'Weighting has been removed',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.$vnode.context.getAdminTasks()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
