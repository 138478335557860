<template>
  <div class="container">
    <Breadcrumb :breadcrumbList="[ { 'name': 'ADMIN DASHBOARD ', 'link': '/admin' },{ 'name': 'TASKS' } ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Tasks</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4">
          <Cards type="svg" image="fa-hourglass-half" title="Pending Tasks" :route="'/admin/tasks/pending'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-clipboard-check" title="Accepted Tasks" :route="'/admin/tasks/accepted'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 ">
          <Cards type="svg" image="fa-ban" title="Declined Tasks" :route="'/admin/tasks/declined'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_TASK_WEIGHTING')">
          <Cards type="svg" image="fa-balance-scale" title="Weighting Tasks" :route="'/admin/tasks/weighting'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_TASK_HERO')">
          <Cards type="svg" icon-type="fa-regular" image="fa-id-badge" title="Tasks Badges" :route="'/admin/tasks/badges'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 " v-if="this.$store.getters.hasRole('ROLE_ADMIN_TASK')">
          <Cards type="svg" image="fa-list" title="Unlisted Tasks" :route="'/admin/tasks/unlisted'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminDisputeLandingPage',
  components: {
    Cards,
    Breadcrumb
  }
}
</script>

<style scoped>

</style>
