<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th class="d-none d-sm-table-cell">{{ $t('username') }}</th>
        <th>{{ $t('actioned_by') }}</th>
        <th>{{ $t('task_advice_title_title') }}</th>
        <th>{{ $t('categories') }}</th>
        <th>{{ $t('total_approval') }}</th>
        <th>{{ $t('last_declined_reason') }}</th>
        <th>{{ $t('date_created') }}</th>
        <th>{{ $t('date_modified') }}</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(task, index) in tasks" :key="index" class="cursor-pointer" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)">
        <td data-title="Username" class="d-none d-sm-table-cell"><i class="fa-solid fa-magnifying-glass mr-2"></i>{{ task[0].user.username }}</td>
        <td data-title="ActionedBy">{{ task[0].decisions[0] !== undefined ? task[0].decisions[0].user.username : '' }}</td>
        <td data-title="Task Title">{{ task[0].title }}</td>
        <td :data-title="$t('categories')">
          <ul class="mb-0 ml-1 pl-2">
            <li v-for="(category,index) in task[0].categories" v-bind:key="index">
              {{ category.parent_category.name }}
              &raquo;
              {{ category.name }}
            </li>
          </ul>
        </td>
        <td :data-title="$t('total_approval')">{{ task[0].decisions.length }}</td>
        <td :data-title="$t('last_declined_reason')">{{ task[0].decisions[0].reason }}</td>
        <td data-title="Date Created">{{ date(task[0].date_created) }}</td>
        <td data-title="Date modified">{{ date(task[0].date_modified) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminDeclinedTaskTable',
  props: {
    tasks: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    }
  }
}
</script>

<style scoped>
ul li {
  list-style: disc;
}
</style>
