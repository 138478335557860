<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table">
      <thead>
      <tr>
        <th>Username</th>
        <th>Task Title</th>
        <th>Category</th>
        <th>Orders accepted</th>
        <th>Date Created</th>
        <th>Date Modified</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3">
      <tr v-for="(task, index) in tasks" :key="index">
        <td>{{ task[0].user.username }}</td>
        <td>{{ task[0].title }}</td>
        <td>
          <ul class="mb-0 ml-1 pl-2">
            <li v-for="(category,index) in task[0].categories" v-bind:key="index">
              {{ category.parent_category.name }} &raquo; {{ category.name }}
            </li>
          </ul>
        </td>
        <td>{{ getOrderAccepted(task.orders_accepted, task.orders_total) }}</td>
        <td>{{ task[0].date_created|formatDate }}</td>
        <td>{{ task[0].date_modified|formatDate }}</td>
        <td>
          <b-button size="sm" variant="primary" class="btn-preview-task mr-2 mb-2" @click="openTaskUrl(task[0].user.username, task[0].slug)">Preview Task</b-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminUnlistedTaskTable',
  props: {
    tasks: Array
  },
  methods: {
    getOrderAccepted (accepted, total) {
      return total > 0 ? (accepted / total * 100).toFixed(2) + '%' : 'n/a'
    },
    openTaskUrl (username, slug) {
      const routeData = this.$router.resolve({ name: 'task_info', params: { username: username, slug: slug } })
      window.open(routeData.href, '_blank')
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) {
        return 'n/a'
      }

      const newDate = new Date(value)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    }
  }
}
</script>
