<template>
  <div class="container mb-3 mt-3">
    <p class="text-right small">*In the last 30 days</p>
    <table class="table table-striped table-bordered add-manage-table">
      <thead>
        <tr>
          <th class="align-top">Username</th>
          <th class="align-top">Task Title</th>
          <th class="align-top">Date Created</th>
          <th class="align-top">Date Modified</th>
          <th class="align-top">Orders Accepted</th>
          <th class="align-top">Rating/Reviews</th>
          <th class="align-top">Dispute In Progress</th>
          <th class="align-top">Dispute in Favour of buyer</th>
          <th class="align-top">Response Rate*</th>
          <th class="align-top">Delivery Rate*</th>
          <th class="align-top">Action</th>
        </tr>
      </thead>
      <tbody class="mb-md-0 mb-3">
        <tr v-for="(task, index) in tasks" :key="index">
          <td>{{ task[0].user.username }}</td>
          <td>{{ task[0].title }}</td>
          <td>{{ task[0].date_created | formatDate }}</td>
          <td>{{ task[0].date_modified | formatDate }}</td>
          <td>{{ getOrderAccepted(task.orders_accepted, task.orders_total) }}</td>
          <td>{{ task[0].review_average }} / {{ task[0].review_count }}</td>
          <td>{{ task.orders_dispute_current }}</td>
          <td>{{ task.dispute_against }}</td>
          <td>{{ task.response_rate | displayPercent }}</td>
          <td>{{ task.delivery_rate | displayPercent }}</td>
          <td>
            <b-button size="sm" variant="primary" class="mr-2 mb-2" @click="openTaskUrl(task[0].user.username, task[0].slug)">Preview Task</b-button>
            <b-button size="sm" variant="danger" class="mb-2" @click="removePopularBadge(task[0].user.username, task[0].slug)">Remove Popular Badge</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiService from '@/apiService'

export default {
  name: 'AdminBadgesTaskTable',
  props: {
    tasks: {
      type: Array
    }
  },
  methods: {
    getOrderAccepted (accepted, total) {
      return total > 0 ? (accepted / total * 100).toFixed(2) + '%' : 'n/a'
    },
    removePopularBadge (username, slug) {
      const vm = this

      this.$swal({
        title: 'Remove Popular Badge',
        text: 'This will remove the Popular badge for this Task',
        type: 'warning',
        showCancelButton: true,
        preConfirm: (result) => {
          if (result === true) {
            return apiService.editTask(slug, { hero: 0, status: 1 }, username)
              .then(() => {})
              .catch((error) => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          vm.$swal({
            title: 'Success',
            text: 'Popular Badge removed',
            type: 'success'
          }).then(() => {
            vm.$vnode.context.getAdminTasks()
          })
        }
      })
    },
    openTaskUrl (username, slug) {
      const routeData = this.$router.resolve({ name: 'task_info', params: { username: username, slug: slug } })
      window.open(routeData.href, '_blank')
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) {
        return 'n/a'
      }

      const newDate = new Date(value)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    },
    displayPercent: function (value) {
      if (!value) {
        return 'n/a'
      }

      return value.toFixed(2) + '%'
    }
  }
}
</script>
