<template>
  <div class="container mt-sm-2">
    <b-alert v-if="taskStatus === 3" :show=true variant="danger" class="mt-3 mb-2 w-100 mx-3">
      <i class="fa-regular fa-circle-xmark align-middle text-danger pr-2"></i>
      {{ $t('task_declined') }} {{ disputeReason }}
    </b-alert>
    <b-alert v-if="taskStatus === 4" :show=true variant="warning" class="mt-4 w-100 text-left d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div>
        <i class="fa-solid fa-triangle-exclamation fa-2x align-middle text-warning mr-1" aria-hidden="true"></i>
        {{ $t('task_status_4_warning') }}
      </div>
      <div class="text-right mt-2 mt-md-0 ml-0 ml-md-3 w-100 w-md-auto mb-2 mb-md-0">
        <a :href="myEtnLink" target="_blank" rel="noopener" class="btn btn-primary w-100 w-md-auto">{{ $t('verification_banner.cta') }}</a>
      </div>
    </b-alert>
    <h1 class="my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">{{ Title }}</h1>
    <div class="row" @mouseout="onclickHints('task')">
      <div class="col-md-8 page-content" ref="formBox">
        <form v-on:submit.prevent="handleSubmit">
          <div class="bg-white p-4 category-content mb-0 rounded-top shadow-sm">
            <h2>{{ $t('task_info') }}</h2>
            <div id="task-form-error-details" class="p-2 alert alert-danger text-left" v-if="errorData.length > 0">
              <p>{{ $t('task_error_form') }}</p>
              <ul>
                <li v-for="(error, index) in errorData" :key="index">{{ error }}</li>
              </ul>
            </div>
            <p class="text-left">{{ $t('task_form_task_description') }}</p>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group row mb-0 pb-3" @mouseover="onclickHints('title')" @click="onclickHints('title')">
                  <label for="taskTitle" class="col-md-3 col-form-label">{{ $t('task_title') }}</label>
                  <div class="col-md-8" data-toggle="tooltip" title="">
                    <input id="taskTitle" v-bind:class="{ 'border-danger': (title_validation === true)}" type="text" class="form-control" v-model="TaskTitle_data" required>
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_title_array').join(' ') }}</em>
                </div>

                <div class="form-group row mb-0 pb-3" @mouseover="onclickHints('description')" @click="onclickHints('description')">
                  <label for="taskDescription" class="col-md-3 col-form-label">{{ $t('task_description') }}</label>
                  <div class="col-md-8">
                    <vue-editor id="taskDescription"
                                v-bind:class="{ 'border-danger': (description_validation === true)}"
                                name="textarea" v-model="TaskDescription_data" required
                                :editorToolbar="customToolbar"/>
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_description_array').join(' ') }}</em>
                </div>

                <div class="form-group row mb-0 pb-3" @mouseover="onclickHints('packageDescription')" @click="onclickHints('packageDescription')">
                  <label for="packageDescription" class="col-md-3 col-form-label">{{ $t('task_package_description') }}</label>
                  <div class="col-md-8">
                    <vue-editor id="packageDescription"
                                v-bind:class="{ 'border-danger': (package_description_validation === true)}"
                                required v-model="TaskPackage_data"
                                name="textarea" :editorToolbar="customToolbar"/>
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_packageDescription_array').join(' ') }}</em>
                </div>

                <div class="form-group row mb-0 pb-3">
                  <label for="packageDescription" class="col-md-3 col-form-label">{{ $t('translation') }}</label>
                  <div class="col-md-8 pt-2" style="font-size:0.9em;">
                    {{ $t('translations_task_info') }}
                    <br>
                    <div v-if="translating_task">
                                            <span class="btn btn-primary my-2 disabled">
                                                {{ $t('translate_to_english') }}
                                            </span>
                      <b-spinner small class="ml-2"></b-spinner>
                    </div>
                    <div v-else>
                                            <span @click="taskTranslate" class="btn btn-primary my-2 cursor-pointer">
                                                {{ $t('translate_to_english') }}
                                            </span>
                    </div>
                    <small>
                      <span style="opacity:0.7; margin-top:1px; margin-right: 2px;">{{ $t('powered_by') }}</span>
                      <img src="@/assets/google-translate.png" style="max-width:120px;">
                    </small>
                  </div>
                </div>
                <div v-if="translating_task_error" class="row">
                  <div class="offset-md-3 col-12 col-md-8 px-3 mb-3">
                    <div class="alert-danger px-3 py-2 text-left rounded">
                      {{ $t('translations_task_error') }}
                    </div>
                  </div>
                </div>
                <div v-if="translating_task_success" class="row">
                  <div class="offset-md-3 col-12 col-md-8 px-3 mb-3">
                    <div class="alert-success px-3 py-2 text-left rounded">
                      {{ $t('translations_task_success') }}
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0 pb-3" @mouseover="onclickHints('delivery')" @click="onclickHints('delivery')">
                  <label for="taskDelivery" class="col-md-3 col-form-label">{{ $t('task_package_delivery') }}</label>
                  <div class="input-group col-md-8">
                    <select id="taskDelivery" :disabled="adminCheck" @change="onChange('language')" class="form-control" v-model="TaskPackageDelivery_data"
                            v-bind:class="{ 'border-danger': (package_delivery_validation  === true)}">
                      <option v-for="(day, index) in $t('daysOptions')" v-bind:key="index" :value="index">{{ day }}</option>
                    </select>
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_delivery_array').join(' ') }}</em>
                </div>

                <div class="form-group row mb-0 pb-3" v-on:mouseover="onclickHints('revisions')" v-on:click="onclickHints('revisions')">
                  <label for="TaskPackageRevisions" class="col-md-3 col-form-label">{{ $t('task_package_revisions') }}</label>
                  <div class="input-group col-md-8">
                    <input min="1" :readonly="adminCheck" v-bind:class="{ 'border-danger': (package_revisions_validation  === true)}" type="number" class="form-control" required
                           v-model="TaskPackageRevisions_data" id="TaskPackageRevisions">
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_revisions_array').join(' ') }}</em>
                </div>
                <div class="from-group pb-3 text-left advice">
                  <h5 class="text-left">{{ $t('price_advice.heading') }}</h5>
                  <p>{{ $t('price_advice.text') }}</p>

                  <p v-if="settings.service_rate">{{ $t('price_advice.service_fee.inc', { 'fee': settings.service_rate }) }}</p>
                  <p v-else>{{ $t('price_advice.service_fee.exc') }}</p>
                </div>
                <div class="form-group row mb-0 pb-3" v-on:mouseover="onclickHints('price')" v-on:click="onclickHints('price')">
                  <label for="taskPrice" class="col-md-3 col-form-label ">{{ $t('task_package_price') }}</label>
                  <div class="input-group col-md-8">
                    <div class="input-group-prepend">
                      <span class="price input-group-text text-gray-dark smaller">$</span>
                    </div>
                    <input id="taskPrice" min="1" max="100" step="1" :readonly="adminCheck" v-on:change="setTwoDecimalPlace"
                           v-bind:class="{ 'border-danger': (package_price_validation  === true)}" type="number" class="form-control" v-model="TaskPackagePrice_data" required>
                  </div>
                  <em class="col-md-3 inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_price_array').join(' ') }}</em>
                </div>
                <div class="form-group row mb-0 pb-3">
                  <label for="etnPrice" class="col-md-3 col-form-label ">{{ $t('estimated_etn_price') }}</label>
                  <div class="input-group col-md-8">
                    <input id="etnPrice" disabled type="text" class="form-control" v-model="etn" required>
                    <div class="input-group-append">
                      <span class="price input-group-text text-gray-dark smaller">ETN</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-on:mouseover="onclickHints('category')" v-on:click="onclickHints('category')">
              <h4 class="my-4 text-left">{{ $t('categories') }}</h4>
              <div class="row pb-3">
                <div class="col-sm-12">
                  <div class="form-group row" v-for="(CategoryRow, index) in getSelectedCategories" :id="'category-group-' + index" v-bind:key="index">
                    <div class="col-9 pr-0">
                      <select name="category-group" :id="'category-group-' + index" class="form-control" ref="Category" @change="onChange('category')"
                              v-bind:class="{ 'border-danger': (categories_validation  === true)}">
                        <option value="default" :selected="CategoryRow.hash === ''"> {{ $t('select') }} {{ $t('category') }} ...</option>
                        <template v-for="category in getCategories">
                          <option :key="category.hash" :value="category.hash" disabled class="text-dark font-weight-bold">{{ category.name }}:</option>
                          <option v-for="childCategory in category.child_categories" v-bind:key="childCategory.hash" :value="childCategory.hash"
                                  :selected="childCategory.hash === CategoryRow.hash">&nbsp;&nbsp;&nbsp;&nbsp;{{ childCategory.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                    <div class="col-md-3 col-2 align-self-center">
                      <a v-on:click="removeCategoryElement('category-group-' + index)" style="cursor: pointer">
                        <i class="fa-regular" v-bind:class="{'fa-trash-can' : (CategoryRowsData.length !== 1)}" v-b-popover.hover.top="$t('delete')" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <em class="col-md-3 inlineHint d-sm-block d-md-none text-left">{{ $t('task_advice_category_array').join(' ') }}</em>
                  </div>
                  <b-button v-if="CategoryRowsData.length < 3" class="float-left py-1 submitButton btn-primary text-white border-0" type="button" @click="addCategoryRow">
                    <small>{{ $t('add') }}</small></b-button>
                </div>
              </div>
            </div>

            <div v-on:mouseover="onclickHints('language')" v-on:click="onclickHints('language')">
              <h4 class="my-4 text-left">{{ $t('languages') }}</h4>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group row" v-for="(LanguageRow, index) in LanguageRowsData" v-bind:key="index">
                    <div class="col-9 pr-0">
                      <select name="language-group" :id="'language-group-' + index" :disabled="adminCheck" @change="onChange('language')" class="form-control" ref="language"
                              v-bind:class="{ 'border-danger': (language_validation === true)}">
                        <option selected="selected" value="default"> {{ $t('select') }} {{ $t('language') }}...</option>
                        <option v-for="language in languageOptions" v-bind:key="language.hash" :value="language.code" :selected="language.code === LanguageRow.code">
                          {{ language.name_local }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 col-2" v-if="!adminCheck">
                      <a v-on:click="LanguageRemoveElement('language-group-' + index)" style="cursor: pointer"><i class="fa"
                                                                                                                  v-bind:class="{'fa-trash' : (LanguageRowsData.length !== 1)}"
                                                                                                                  v-b-popover.hover.top="$t('delete')" aria-hidden="true"></i></a>
                    </div>
                  </div>
                  <div class="row">
                    <em class="col-md-3 inlineHint d-sm-block d-md-none text-left">{{ $t('task_advice_language_array').join(' ') }}</em>
                  </div>
                  <b-button class="float-left py-1 submitButton btn-primary text-white border-0" type="button" @click="LanguageAddRow" v-if="!adminCheck"><small>{{
                      $t('add')
                    }}</small></b-button>
                </div>
              </div>
            </div>
            <div class="row" v-on:mouseover="onclickHints('asset')" v-on:click="onclickHints('asset')">
              <div v-if="FileRows_data.length > 0" class="col-sm-12">
                <h4 class="my-4 text-left">{{ $t('current_asset') }}</h4>
                <div class="form-group row">
                  <div v-for="(FileRow, index) in FileRows_data" v-bind:key="index" class="col-12 col-md-6 col-lg-4 p-2">
                    <div class="p-2" style="border: dashed 1px #ddd;">
                      <a v-on:click="FileRemoveElement(FileRow.hash)" v-if="!(adminCheck && FileRows_data.length === 1)" style="cursor: pointer">
                        <i class="fa-regular fa-trash-can float-right mb-1" v-b-popover.hover.top="$t('delete')"></i></a>
                      <img v-if="FileRow.type === 1" :src="formatAssetUrl(FileRow.path, 'task')"/>
                      <video v-else :src="formatAssetUrl(FileRow.path, 'movie_mp4')" controls style="position:relative;"></video>
                      <input type="hidden" ref="files" :value="FileRow.hash" readonly>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <h4 class="my-4 text-left">{{ $t('task_assets') }}</h4>
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true
                              :awss3="awss3"
                              v-on:vdropzone-file-added='dropzoneAdded'
                              v-on:vdropzone-removed-file='dropzoneRemoved'
                              v-on:vdropzone-error="dropzoneError"
                              v-on:vdropzone-s3-upload-error="s3UploadError"
                              v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                              v-on:vdropzone-files-added="preFileUpload"
                              v-bind:class="{ 'border-danger': (asset_validation === true)}">
                  <div class="dropzone-custom-content text-muted">
                    <i class="fa-solid fa-cloud-arrow-up"></i>
                    <br/>
                    {{ $t('dropzone_placeholder') }}
                  </div>
                </vue-dropzone>
                <div class="row p-3 alert mb-0 pb-0">
                  <em class="inlineHint text-left">
                    {{ $t('task_advice_asset_array').join(' ') }}
                    {{ $t('upload_advice_image_video').join(' ') }}
                  </em>
                </div>
              </div>
            </div>

            <div v-on:click="onclickHints('slug')" v-on:mouseover="onclickHints('slug')">
              <h4 class="my-4 text-left">{{ $t('task_slug') }}</h4>
              <p class="text-left">{{ $t('task_form_slug_description') }}</p>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group row">
                    <div class="input-group col-12">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-gray text-gray-dark smaller">anytask.com/{{ Username_data.toLowerCase() }}/</span>
                      </div>
                      <input id="taskSlug" :readonly="Disabled" pattern="/^([a-zA-Z0-9_]+)$/" v-bind:class="{ 'border-danger': (slug_validation  === true)}" type="text"
                             class="form-control" v-model="TaskSlug_data" required>
                    </div>
                  </div>
                  <div class="col-12 mb-2 ml-1 text-left" v-if="!adminCheck">
                    <small>
                      <input type="checkbox" class="form-check-input" id="editRoute" v-on:click="editRoute()">
                      <label class="form-check-label" for="editRoute">{{ $t('edit_route') }}</label>
                    </small>
                    <div class="row">
                      <em class="inlineHint d-sm-block d-md-none mt-2">{{ $t('task_advice_slug_array').join(' ') }}</em>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="adminCheck && this.$store.getters.hasRole('ROLE_ADMIN_TASK_WEIGHTING')">
              <h4 class="my-4 text-left">Weighting</h4>
              <div class="form-group row mb-0 pb-3">
                <label for="TaskPackageRevisions" class="col-md-3 col-form-label">Weighting</label>
                <div class="input-group col-md-8">
                  <input min="0" step="0.01" v-on:change="setTwoDecimalPlaceWeighting" v-bind:class="{ 'border-danger': (weighting_validation  === true)}" type="number"
                         class="form-control" required v-model="weighting_data" id="HiddenWeighting">
                </div>
                <div class="col-12 col-md-8 offset-md-3 mt-3">
                  <b-button v-if="weighting_data !== 1" class="float-right py-1 submitButton btn-primary text-white border-0" type="button" @click="removeWeighting()"><small>Remove
                    weighting</small></b-button>
                </div>
              </div>
            </div>

            <div class="text-left" v-if="adminCheck && this.$store.getters.hasRole('ROLE_ADMIN_TASK_HERO')">
              <h4 class="my-4 text-left">Badges</h4>
              <label id="heroLabel" class="form-check-label mr-3" for="hero">Popular</label>
              <input type="checkbox" id="hero" v-model="popular_data">
            </div>

          </div>
          <div v-if="uploading" class="mb-5">
            <b-button class="mt-3 submitButton w-100 btn-primary text-white border-0" type="button" block>
              <b-spinner class="" small label="Large Spinner"/>
            </b-button>
          </div>
          <div v-else class="row my-3 flex-row-reverse">
            <div class="col-12 col-sm-4 mb-4">
              <a class="btn btn-danger w-100" @click="$router.push({ name: 'myTasks'})">{{ $t('cancel') }}</a>
            </div>
            <div class="col-12 col-sm-8 mb-4">
              <a class="btn btn-primary text-white w-100" :disabled="dropzoneUploading" v-on:click="handleSubmit">{{ adminCheck ? 'Save Changes' : $t('task_save') }}</a>
            </div>
          </div>
        </form>
      </div>

      <div class="reg-sidebar d-none d-md-block col-md-4">
        <div class="reg-sidebar-inner text-center hint sticky-top" v-bind:class="{'sticky' : (sticky)}">
          <div class="card sidebar-card mb-0 shadow-sm">
            <div class="card-header uppercase">
              <strong>{{ hints.title }}</strong>
            </div>
            <div class="card-content">
              <div class="card-body text-left">
                <p v-for="(hint, index) in hints.items" v-bind:key="index">{{ hint }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../../config'
import vueDropzone from 'vue2-dropzone'
import apiService from '../../../apiService.js'
import helper from '../../../helper.js'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'TaskForm',
  props: {
    CategoryRows: Array,
    LanguageRows: Array,
    FileRows: Array,
    TaskPackage: {
      type: String,
      required: false,
      default: ''
    },
    TaskPackageDelivery: Number,
    TaskPackageRevisions: {
      type: Number,
      required: false,
      default: 0
    },
    TaskPackagePrice: {
      type: Number,
      required: false,
      default: 0
    },
    TaskDescription: {
      type: String,
      required: false,
      default: ''
    },
    TaskSlug: String,
    TaskTitle: {
      type: String,
      required: false,
      default: ''
    },
    Title: String,
    type: {
      type: String,
      required: false,
      default: 'create'
    },
    reason: {
      type: String,
      required: false,
      default: null
    },
    status: {
      type: Number,
      required: false,
      default: 0
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    },
    username: {
      type: String,
      required: false,
      default: localStorage.getItem('username')
    },
    taskUserHash: {
      type: String,
      required: true
    },
    hash: {
      type: String,
      required: false,
      default: ''
    },
    weighting: {
      type: String,
      required: false,
      default: '0'
    },
    hero: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    vueDropzone,
    VueEditor
  },
  data () {
    return {
      customToolbar: [['bold', 'italic', 'underline']],
      settings: JSON.parse(sessionStorage.getItem('settings')),
      etn: 0.00,
      convertedRate: 0,
      disputeReason: this.reason,
      taskStatus: this.status,
      assetsData: [],
      assetsDropzoneData: {},
      adminCheck: this.admin,
      // imageHintSrc: require('@/assets/task-preview-images/task.png'),
      dropzoneUploading: false,
      awss3: {
        signingURL: apiService.getTokenUrl('task'),
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: {
          'hash': this.taskUserHash
        },
        sendFileToServer: false
      },
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 500,
        maxFiles: 10,
        autoProcessQueue: true,
        parallelUploads: 2,
        timeout: 0,
        parallelChunkUploads: false,
        retryChunks: true,
        retryChunksLimit: 3,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        acceptedFiles: 'application/mpd,application/vnd.apple.mpegurl,video/3gpp2,video/3gpp,video/mp4,video/mpeg,video/ogg,video/quicktime,video/webm,video/x-msvideo,video/x-flv,video/x-matroska,video/x-ms-asf,video/x-ms-wmv,application/postscript,image/bmp,image/gif,image/jp2,image/jpeg,image/png,image/svg+xml,image/tif,image/tiff,image/vnd.ms-photo,image/vnd.adobe.photoshop,image/webp,image/x-icon',
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      },
      TaskSlugTitle: this.TaskSlug,
      userHash: this.taskUserHash,
      Disabled: true,
      sticky: false,
      url: [],
      Username_data: localStorage.getItem('username'),
      FileRows_data: this.FileRows,
      TaskPackage_data: this.TaskPackage,
      TaskPackageDelivery_data: this.TaskPackageDelivery,
      TaskPackageRevisions_data: this.TaskPackageRevisions,
      TaskPackagePrice_data: this.TaskPackagePrice,
      TaskDescription_data: this.TaskDescription,
      TaskSlug_data: this.TaskSlug,
      TaskTitle_data: this.TaskTitle,
      CategoryRowsData: this.CategoryRows,
      LanguageRowsData: this.LanguageRows,
      weighting_data: this.weighting,
      popular_data: this.hero,
      Categories: [],
      languageOptions: [],
      FilesRowData: [],
      validationMessage: {},
      validationType: false,
      validationSwitch: false,
      title_validation: false,
      slug_validation: false,
      description_validation: false,
      package_description_validation: false,
      package_delivery_validation: false,
      package_revisions_validation: false,
      package_price_validation: false,
      categories_validation: false,
      language_validation: false,
      asset_validation: false,
      delivery_validation: false,
      weighting_validation: false,
      hero_validation: false,
      upload_data: null,
      uploading: false,
      errorData: [],
      hints: {
        title: this.$t('task_advice_title_title'),
        items: this.$t('task_advice_title_array')
      },
      translating_task: false,
      translating_task_error: false,
      translating_task_success: false,
      myEtnLink: config.my_etn
    }
  },
  watch: {
    'weighting_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.hiddenWeighting_validation = false
      }
    },
    'popular_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.hero_validation = false
      }
    },
    'TaskPackage_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.package_description_validation = false
      }
    },
    'TaskPackageDelivery_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.package_delivery_validation = false
      }
    },
    'TaskPackageRevisions_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.package_revisions_validation = false
      }
    },
    'TaskPackagePrice_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.package_price_validation = false
        this.etn = (val / this.convertedRate).toFixed(2)
      }
    },
    'TaskDescription_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.description_validation = false
      }
    },
    'TaskSlug_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.slug_validation = false
      }
    },
    'TaskTitle_data': function (val, oldVal) {
      if (val !== oldVal) {
        this.title_validation = false
      }
      if (this.TaskSlug === undefined) {
        this.TaskSlug_data = val
        this.TaskSlug_data = this.TaskSlug_data.normalize('NFKD').replace(/[\u0300-\u036F]/g, '')
        this.TaskSlug_data = this.TaskSlug_data.toLowerCase().replace(/[^\w\d\s]/g, '')
        this.TaskSlug_data = this.TaskSlug_data.toLowerCase().replace(/\s/g, '-')
        this.TaskSlug_data = this.TaskSlug_data.replace(/-{2,}/g, '-')
        this.TaskSlug_data = this.TaskSlug_data.replace(/(^-)|(-$)/g, '')
      }
    }
  },
  mounted () {
    this.getAllCategories()
    this.FileAddRow()
    this.getAllLanguages()
    this.getExchangeRate()
    this.dropzoneSetUp()
  },
  methods: {
    dropzoneSetUp () {
      const maxFiles = this.dropzoneOptions.maxFiles
      this.$refs.myVueDropzone.dropzone.on('addedfile', function (file) {
        if (this.files.length > maxFiles) {
          this.removeFile(this.files[0])
        }
      })
      this.$refs.myVueDropzone.dropzone.on('maxfilesexceeded', function (file) {
        this.removeFile(file)
      })
    },
    dropzoneAdded (file) {
      this.asset_validation = false
    },
    dropzoneRemoved (file) {
      let originalDropzoneData = this.assetsDropzoneData
      delete originalDropzoneData[file.upload.filename]
      this.assetsDropzoneData = originalDropzoneData
      this.asset_validation = this.$refs.myVueDropzone.dropzone.getAcceptedFiles().length === 0
    },
    dropzoneError (file, errorMessage) {
      this.errorData.push(errorMessage)
      this.dropzoneUploading = false
    },
    onChange (event) {
      if (event === 'language') {
        this.language_validation = false
      }
      if (event === 'category') {
        this.categories_validation = false
      }
      if (event === 'delivery') {
        this.delivery_validation = false
      }
    },
    onclickHints (type) {
      if (type === 'description') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-description.png')
        this.hints.title = this.$t('task_advice_title_description')
        this.hints.items = this.$t('task_advice_description_array')
      } else if (type === 'packageDescription') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-package-description.png')
        this.hints.title = this.$t('task_advice_title_packageDescription')
        this.hints.items = this.$t('task_advice_packageDescription_array')
      } else if (type === 'delivery') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-delivery.png')
        this.hints.title = this.$t('task_advice_title_delivery')
        this.hints.items = this.$t('task_advice_delivery_array')
      } else if (type === 'revisions') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-title-revisions.png')
        this.hints.title = this.$t('task_advice_title_revisions')
        this.hints.items = this.$t('task_advice_revisions_array')
      } else if (type === 'price') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-price.png')
        this.hints.title = this.$t('task_advice_price_title')
        this.hints.items = this.$t('task_advice_price_array')
      } else if (type === 'category') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-category.png')
        this.hints.title = this.$t('task_advice_category_title')
        this.hints.items = this.$t('task_advice_category_array')
      } else if (type === 'language') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-language.png')
        this.hints.title = this.$t('task_advice_language_title')
        this.hints.items = this.$t('task_advice_language_array')
      } else if (type === 'asset') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-asset.png')
        this.hints.title = this.$t('task_advice_asset_title')
        this.hints.items = this.$t('task_advice_asset_array')
      } else if (type === 'slug') {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-slug.png')
        this.hints.title = this.$t('task_advice_title_slug')
        this.hints.items = this.$t('task_advice_slug_array')
      } else {
        // this.imageHintSrc = require('@/assets/task-preview-images/task-title.png')
        this.hints.title = this.$t('task_advice_title_title')
        this.hints.items = this.$t('task_advice_title_array')
      }
    },
    handleSubmit () {
      this.uploading = true
      if (this.validateForm()) {
        this.createTaskApi()
      }
    },
    validateForm () {
      let languageList = []
      this.$refs.language.map((m, k) => {
        if (m.value !== 'default') {
          languageList.push(m.value)
        }
      })

      let categoryList = []
      this.$refs.Category.map((m, k) => {
        if (m.value !== 'default') {
          categoryList.push(m.value)
        }
      })

      let slugData = this.TaskSlug_data
      if (slugData !== undefined) {
        slugData.toLowerCase()
      }
      let TaskDescriptionData = this.TaskDescription_data
      if (TaskDescriptionData !== undefined) {
        TaskDescriptionData = this.$sanitize(TaskDescriptionData)
      }
      let TaskPackageData = this.TaskPackage_data
      if (TaskPackageData !== undefined) {
        TaskPackageData = this.$sanitize(TaskPackageData)
      }

      // const assetsArr = this.assetsData
      let assetsData = this.assetsData
      if (this.FileRows_data.length > 0) {
        this.FileRows_data.forEach(function (item) {
          let find = assetsData.find(function (element) {
            return element === item.hash
          })
          if (!find) {
            assetsData.push(item.hash)
          }
        })
      }

      let dropzoneData = Object.keys(this.assetsDropzoneData)
      if (dropzoneData.length > 0) {
        dropzoneData.forEach((key, index) => {
          let find = assetsData.find(function (element) {
            return element === dropzoneData[key]
          })
          if (!find) {
            assetsData.push(this.assetsDropzoneData[key])
          }
        })
      }

      let data = {
        'title': this.TaskTitle_data,
        'slug': slugData,
        'description': TaskDescriptionData,
        'categories': categoryList,
        'assets': this.assetsData,
        'languages': languageList,
        'packages': [
          {
            'description': TaskPackageData,
            'delivery': this.TaskPackageDelivery_data,
            'revisions': this.TaskPackageRevisions_data,
            'price': this.TaskPackagePrice_data
          }
        ]
      }

      // Clear existing errors
      this.errorData = []
      let errorData = []

      if (data.title === undefined || data.title === '') {
        this.title_validation = true
        errorData.push(this.$t('task_title'))
      }

      if (data.description === undefined || data.description === '') {
        this.description_validation = true
        errorData.push(this.$t('task_description'))
      }

      if (data.packages[0].description === undefined || data.packages[0].description === '') {
        this.package_description_validation = true
        errorData.push(this.$t('task_package_description'))
      }

      if (data.packages[0].delivery === undefined || data.packages[0].delivery === '') {
        this.package_delivery_validation = true
        errorData.push(this.$t('task_package_delivery'))
      }

      if (data.packages[0].revisions === undefined || data.packages[0].revisions === '') {
        this.package_revisions_validation = true
        errorData.push(this.$t('task_package_revisions'))
      }

      if (data.packages[0].price === undefined || data.packages[0].price === '') {
        this.package_price_validation = true
        errorData.push(this.$t('task_package_price'))
      }

      if (data.categories.length === 0) {
        this.categories_validation = true
        errorData.push(this.$t('category'))
      }

      if (data.languages.length === 0) {
        this.language_validation = true
        errorData.push(this.$t('language'))
      }

      if (this.assetsData.length === 0 && this.$refs.myVueDropzone.dropzone.getQueuedFiles().length === 0) {
        this.asset_validation = true
        errorData.push(this.$t('task_assets'))
      }

      if (data.slug === undefined || data.slug === '') {
        this.slug_validation = true
        errorData.push(this.$t('task_slug') + ' ' + this.$t('wrong_format_slug'))

        document.getElementById('editRoute').checked = true
        this.editRoute()
      }

      if (!this.slug_validation && !RegExp('^([a-z0-9-]+)$').test(data.slug)) {
        this.slug_validation = true
        errorData.push(this.$t('task_slug') + ' ' + this.$t('wrong_format_slug'))

        document.getElementById('editRoute').checked = true
      }

      if (this.adminCheck && this.$store.getters.hasRole('ROLE_ADMIN_TASK_WEIGHTING')) {
        if (this.weighting_data === undefined || this.weighting_data === '') {
          this.weighting_validation = true
          errorData.push(this.$t('weighting_validation'))
        } else {
          data['weighting'] = this.weighting_data
        }
      }

      if (this.adminCheck && this.$store.getters.hasRole('ROLE_ADMIN_TASK_HERO')) {
        data['hero'] = this.popular_data
      }

      // If we have errors, show them and scroll to the top
      if (errorData.length > 0) {
        this.errorData = errorData
        window.scrollTo(0, 0)
        this.uploading = false
        return false
      }

      this.upload_data = data
      return true
    },

    errorHandling (resp) {
      let errorData = []
      if (resp.code === 'HTTP-400') {
        for (let key in resp.errors.children) {
          if (key === 'packages') {
            let errorRoute = resp.errors.children.packages.children[0].children
            for (let packageKey in errorRoute) {
              if (errorRoute[packageKey].errors !== undefined) {
                let key = 'package_' + packageKey + '_validation'
                this[key] = true
                errorData.push(errorRoute[packageKey].errors[0])
              }
            }
          } else if (resp.errors.children[key].errors !== undefined) {
            let errors = resp.errors.children[key].errors
            if (!Array.isArray(errors)) {
              errors = [errors]
            }
            errors.forEach((error) => {
              errorData.push(error)
            })
            let keyValidation = key + '_validation'
            this[keyValidation] = true
          }
        }
      } else {
        if (resp.code === 'HTTP-409') {
          this.slug_validation = true
        }
        this.validationMessage = resp.error_message

        errorData.push(this.validationMessage)
      }
      this.errorData = errorData
      this.uploading = false
      window.scrollTo(0, 0)
    },
    createTaskApi () {
      // Ensure we are OK to continue
      if (this.uploading === false) {
        return
      }
      if (this.TaskSlug !== undefined) {
        apiService.editTask(this.TaskSlug, this.upload_data, this.username).then(() => {
          this.$router.push({ name: 'task_info', params: { username: this.username, slug: this.upload_data.slug, 'activateEdit': '#edit' } })
        }).catch((error) => {
          this.errorHandling(error.response.data)
          return false
        })
      } else {
        apiService.createTask(this.upload_data).then((resp) => {
          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'taskCreate',
              'sellerID': this.$store.state.user.hash,
              'taskID': resp.data.data.task.hash
            })
          }

          if (config.tracking_enabled === 'true') {
            window.__at_px.fire('task_created')
          }

          setTimeout(() => {
            this.$router.push('/' + localStorage.getItem('username') + '/' + this.upload_data.slug)
          }, 250)
        }).catch((error) => {
          this.errorHandling(error.response.data)
          return false
        })
      }
    },
    getAllLanguages () {
      apiService.getAllLanguages().then((data) => {
        this.languageOptions = data.data['data']
      }).catch(() => {
      })
    },
    getAllCategories () {
      apiService.getAllCategories().then((data) => {
        this.Categories = JSON.parse(JSON.stringify(data.data.data))
        for (let i = 0; i < this.Categories.length; i++) {
          this.Categories[i].child_categories = this.Categories[i].child_categories.filter((category) => !category.divider)
        }
      }).catch(() => {
      })
    },
    addCategoryRow: function () {
      document.createElement('div')
      this.CategoryRowsData.push({
        hash: ''
      })
    },
    removeCategoryElement: function (index) {
      let categoryList = []

      this.$refs.Category.map((m, k) => {
        if (String(m.id) !== String(index)) {
          categoryList.push({ 'hash': m.value })
        }
      })
      this.CategoryRowsData = categoryList
    },
    LanguageAddRow: function () {
      document.createElement('div')
      this.LanguageRowsData.push({
        value: ''
      })
    },
    LanguageRemoveElement: function (index) {
      let languageList = []
      this.$refs.language.map((m, k) => {
        if (String(m.id) !== String(index)) {
          languageList.push({ code: m.value })
        }
      })
      this.LanguageRowsData = languageList
    },
    FileAddRow: function () {
      // Before adding a new input, we'll remove any unused ones
      let els = document.getElementsByClassName('file')
      let canAdd = true
      Array.from(els).forEach(function (el) {
        let e = el.parentNode.parentNode
        if (e.style.display === '') {
          canAdd = false
        }
      })

      if (canAdd === true) {
        this.FilesRowData.push({
          path: null
        })

        // Click the browse button
        // let files = document.querySelectorAll('.file')
        // if (files.length > 0) {
        //   files[(files.length - 1)].click()
        // }
      }
    },
    FileRemoveElement (hash) {
      let FileList = []
      this.FileRows_data.forEach(function (item) {
        if (item.hash !== hash) {
          FileList.push(item)
        }
      })
      this.FileRows_data = FileList
    },
    editRoute () {
      if (this.Disabled) {
        this.Disabled = false
      } else {
        this.Disabled = true
      }
    },
    getExchangeRate () {
      apiService.getConversionRate().then(response => {
        this.convertedRate = response.data.data['USD'].etn_rate
        this.etn = (this.TaskPackagePrice_data / this.convertedRate).toFixed(2)
      }).catch(() => {
      })
    },
    setTwoDecimalPlace (e) {
      this.TaskPackagePrice_data = parseFloat(e.target.value).toFixed(2)
    },
    setTwoDecimalPlaceWeighting (e) {
      this.weighting_data = parseFloat(e.target.value).toFixed(2)
    },
    formatAssetUrl (url, type) {
      return helper.formatCloudinaryUrl(url, type)
    },
    taskTranslate () {
      this.translating_task_error = false
      this.translating_task_success = false

      const data = {}
      if (this.TaskTitle_data) {
        data['task_title'] = this.TaskTitle_data
      }
      if (this.TaskDescription_data) {
        data['task_description'] = this.TaskDescription_data
      }
      if (this.TaskPackage_data) {
        data['package_description'] = this.TaskPackage_data
      }

      if (Object.keys(data).length > 0) {
        this.translating_task = true

        apiService.getTranslation(data, 'en').then(response => {
          this.translating_task = false
          this.translating_task_success = true

          if (typeof response.data.data.task_title !== 'undefined') {
            this.TaskTitle_data = response.data.data.task_title
          }
          if (typeof response.data.data.task_description !== 'undefined') {
            this.TaskDescription_data = response.data.data.task_description
          }
          if (typeof response.data.data.package_description !== 'undefined') {
            this.TaskPackage_data = response.data.data.package_description
          }
        }).catch(() => {
          this.translating_task = false
          this.translating_task_error = true
        })
      }
    },
    s3UploadError (file, errorMessage) {
      this.errorData.push(this.$t('task_package_price'))
    },
    s3UploadSuccess (s3ObjectLocation) {
      apiService.addToGallery(s3ObjectLocation, this.adminCheck ? this.userHash : null).then(response => {
        this.assetsDropzoneData[s3ObjectLocation.split('%2F').pop()] = response.data.data.hash
        this.dropzoneUploading = false
      }).catch((error) => {
        if (error.response.data.code === 'HTTP-400') {
          this.errorData.push(error.response.data.errors.children.asset.errors[0])
        } else {
          this.errorData.push(error.response.data.error_message)
        }
        window.scrollTo(0, 0)
        this.asset_validation = true
        this.dropzoneUploading = false
      })
    },
    preFileUpload (file, xhr, formData) {
      this.dropzoneUploading = true
      this.errorData = []
      this.asset_validation = false
    },
    removeWeighting () {
      let vm = this
      this.$swal({
        title: 'Remove weighting',
        text: 'This will remove weighting and task will no longer be boosted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            let uploadData = {
              'weighting': 1,
              'status': 1
            }
            return apiService.editTask(vm.TaskSlug, uploadData, vm.username).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: 'Success',
            text: 'Weighting has been removed',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            vm.weighting_data = 1
          })
        }
      })
    }
  },
  computed: {
    getCategories () {
      if (this.adminCheck) {
        return this.Categories
      }
      return this.Categories.filter(function (val) {
        return val.hash.substring(0, 5) !== 'admin'
      })
    },
    getSelectedCategories () {
      if (this.adminCheck) {
        return this.CategoryRowsData
      }
      return this.CategoryRowsData.filter(function (val) {
        if (typeof val.hash === 'string') {
          return val.hash.substring(0, 5) !== 'admin'
        }
        return true
      })
    }
  }
}
</script>

<style scoped>
#task-create-overlay {
  position: fixed;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  margin-top: -3rem;
  padding: 3rem 1rem 1rem 1rem;
}

em {
  font-style: normal;
  color: gray;
  margin: -5px 0 5px 0;
}

.hint {
  z-index: 1;
  top: 10%;
}

.submitButton {
  margin: 0 auto;
}

.form-group {
  text-align: left;
}

li, ul {
  list-style-type: unset;
}

.btn-file {
  border: 1px solid #ced4da;
}

.smaller {
  font-size: 80%;
}

.border-danger {
  border-width: 2px;
  border-style: solid;
}

.inlineHint {
  font-size: 14px;
}

.price {
  height: 38px;
}

.img-thumbnail {
  display: block;
  margin-top: -1.5em;
}

.asset-thumbnail-remove {
  cursor: pointer;
  position: relative;
  top: 1em;
  left: 1em;
  z-index: 10;
  background-color: #fff;
  border-radius: 20%;
  padding: 5px 8px;
  opacity: 0.6;
}

.asset-thumbnail-remove:hover {
  opacity: 1;
}

.fa-trash-can {
  display: none;
}

.display-none {
  display: none;
}

.advice p {
  font-size: small;
}
</style>

<style lang="scss" scoped>
@include md-up {
  .w-md-auto {
    width: auto !important;
  }
}
</style>

<style>
#task-form-error-details ul li {
  list-style-type: disc;
  margin-left: 3em;
}

.dropzone {
  border: 2px dashed #dedede;
  border-radius: 5px;
  background: #f5f5f5;
  overflow: auto;
}

.dropzone i {
  font-size: 5rem;
  color: #bbb;
}

.dropzone .dz-message {
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
}

.dropzone .dz-preview.dz-image-preview {
  background-color: transparent !important;
  float: left;
  margin: 0;
  padding: 8px;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #28A745;
}

.dropzone .dz-preview .dz-progress {
  border: 1px solid white;
}

/** Fix sticky top overlay issue */
.reg-sidebar-inner {
  margin-top: -30px;
  padding-top: 30px;
}

.btn-task-translate {
  border: 1px solid #ced4da;
  cursor: pointer;
  max-width: 150px;
}
</style>
