<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th class="d-none d-sm-table-cell">{{ $t('username') }}</th>
        <th>{{ $t('task_advice_title_title') }}</th>
        <th>{{ $t('date_created') }}</th>
        <th>Approve task</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(task, index) in tasks" :key="index" class="cursor">
        <td data-title="Username" class="d-none d-sm-table-cell">
          {{ task[0].user.username }}
        </td>
        <td data-title="Task Title">{{ task[0].title }}</td>
        <td data-title="Date Created">{{ date(task[0].date_created) }}</td>
        <td>
          <router-link :to="{name:'admin_task_approve_with_hash', params:{hash: task[0].hash}}">
            <button class="btn btn-primary mr-2 w-100" type="button">
              Review task
            </button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminPendingTaskTable',
  props: {
    tasks: Array
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    }
  }
}
</script>

<style scoped>

</style>
