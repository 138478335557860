<template>
  <div>
    <TaskForm v-if="taskLoaded" :CategoryRows="tasks.categories" :username="tasks.user.username" :LanguageRows="tasks.languages" :FileRows="tasks.assets" :TaskPackage="this.$sanitize(tasks.packages[0].description)"
              :TaskPackageDelivery="tasks.packages[0].delivery" :weighting="tasks.hidden_weighting.toFixed(2)"
              :TaskPackageRevisions="tasks.packages[0].revisions" :TaskPackagePrice="tasks.packages[0].price" :TaskDescription="this.$sanitize(tasks.description)" :TaskSlug="tasks.slug" :TaskTitle="tasks.title" type="edit" Title="Edit task (Admin)"
              :reason="reason" :status="this.tasks.status" :admin="true" :task-user-hash="tasks.user.hash" :hash="tasks.hash" :hero="tasks.hero"
    />
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"/>
      <div>{{ $t('loading_task_info') }}</div>
    </div>
  </div>
</template>

<script>
import TaskForm from '../../../components/Admin/Task/AdminTaskForm.vue'
import apiService from '../../../apiService.js'
export default {
  name: 'AdminEditTask',
  components: {
    TaskForm
  },
  data () {
    return {
      tasks: [],
      languages: [],
      taskLoaded: false,
      reason: '',
      username: this.$route.params.username
    }
  },
  mounted () {
    this.getTask()
  },
  methods: {
    getTask () {
      apiService.getTaskByUser(this.$route.params.username, this.$route.params.slug).then((data) => {
        if (data.status === 400) {
          this.$router.push('/')
        }
        this.tasks = data.data['data'][0]

        if (this.tasks.status !== 1) {
          this.$route.push('/400')
        }

        if (this.tasks.decisions.length > 0) {
          this.reason = this.tasks.decisions[this.tasks.decisions.length - 1].reason
        }
        this.taskLoaded = true
      }).catch((e) => {})
    }
  }
}
</script>
