<template>
  <b-modal id="DeclineModal" title="Deactivate live task" ref="modal" @show="resetModal" @hidden="resetModal" :hide-footer="true" header-class="mb-3" footer-class="border-0"
           ok-only>
    <b-container fluid class="mb-5">
      <div :id="'taskWarning'" class="p-1 alert alert-danger text-left mb-3 p-2" style="display: none">
        <i class="fa-solid fa-triangle-exclamation"></i>
        {{ $t('decision_missing_reason') }}
      </div>
      <div :id="'taskWarningApi'" class="p-1 alert alert-danger text-left m-0" style="display:none;">
        <ul>
          <li v-for="(error, index) in errorMessagesApi" v-bind:key="index">{{ error }}</li>
        </ul>
      </div>
      <div>
        <select class="mb-2 custom-select" @change="updateDeclineMessage($event.target.value)">
          <option value="">Select a declined response...</option>
          <option v-for="(value, index) in decline_messages" :key="index" :value="value.text">{{ value.title }}</option>
        </select>
        <textarea :id="'taskDecision'" placeholder="Enter a reason to deactivate task" class="form-control mb-3" name="textarea" rows="3"/>
        <p class="text-left alert alert-warning p-2">
          <small>
            This text will be sent to the user. Please be brief and concise.
          </small>
        </p>
        <div class="align-center">
          <b-button v-if="loading" class="btn-primary text-white border-0 w-100">
            <b-spinner small></b-spinner>
          </b-button>
          <b-button v-else :id="'btn-decline'" v-on:click="decision()" class="w-100" variant="danger">
            Submit reason for deactivating task
          </b-button>
        </div>
      </div>
    </b-container>

  </b-modal>
</template>

<script>
import APIService from '../../../apiService'

export default {
  name: 'DeclineTaskPopUp',
  props: {
    hash: String
  },
  data () {
    return {
      show: true,
      loading: false,
      errors: [],
      errorMessagesApi: [],
      submitted: false,
      formData: {
        reason: '',
        status: null
      },
      decline_messages: [
        {
          'id': 'clearer_description',
          'title': 'Description needs to be clearer',
          'text': 'Buyers like detailed descriptions of exactly what you will be doing for them. Include as much detail as possible.'
        },
        {
          'id': 'clearer_product',
          'title': 'Final Product needs to be clearer',
          'text': 'You must let the buyer know what to expect as a final product. What will they get delivered? This could be a certain digital file type (such as pdf or multiple image formats or it could be the time you will spend on the task etc.)'
        },
        {
          'id': 'lack_of_description_product',
          'title': 'Lack of Information in ‘Description’ and, or ‘Final Product’',
          'text': 'Sorry, there is not enough information in your description/final product field for your task to be clear to the buyers so they can understand what you will be doing for them.  Please insert further details and resubmit your task.'
        },
        {
          'id': 'not_english',
          'title': 'Task not in English',
          'text': 'Sorry, at this time AnyTask only supports tasks written in English. We hope to bring other languages to AnyTask in the future, but in the meantime, please resubmit your task in English.'
        },
        {
          'id': 'inappropriate_content',
          'title': 'Inappropriate ‘Username’, ‘Task’ and, or ‘Task Header’',
          'text': 'Your Username/Task/Task Header is not suitable for approval and does not meet the Terms and Conditions.'
        },
        {
          'id': 'illegal',
          'title': 'Illegal Task (I.e. Drugs etc...)',
          'text': 'Your task is not suitable for approval and does not meet the Terms and Conditions of AnyTask.com.'
        },
        {
          'id': 'how_complete',
          'title': 'Lack of Explanation into ‘how’ your Task will be completed',
          'text': 'Sorry, your task does not explain how this will be completed.  Please feel free to insert more details and resubmit your task.'
        },
        {
          'id': 'physical_products',
          'title': 'Physical Products',
          'text': 'Sorry, we do not accept tasks selling physical products.'
        },
        {
          'id': 'donation_requests',
          'title': 'Donation Requests',
          'text': 'Sorry, we do not accept Donation requests, AnyTask is a digital freelancer platform.'
        },
        {
          'id': 'grammatical_errors',
          'title': 'Grammatical Errors',
          'text': 'Sorry, we are unable to accept your task based on some grammatical errors in your task. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'long_description',
          'title': 'Very long description',
          'text': 'Sorry, your task explanation/description is slightly too long and will be hard for the Buyer to read through. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'banned_tasks',
          'title': 'Money Making Schemes / Betting Schemes / No trading systems',
          'text': 'AnyTask does not accept tasks of this type, they are against the Terms and Conditions.'
        },
        {
          'id': 'selling_crypto',
          'title': 'Selling ETN or Cryptocurrencies',
          'text': 'Sorry, you cannot sell cryptocurrencies as a task. It is against the Terms and Conditions.'
        },
        {
          'id': 'inappropriate_category',
          'title': 'Inappropriate category / categories',
          'text': 'Your listing is acceptable, but you have listed it in one or more categories that are not appropriate.  Please resubmit using appropriate categories.'
        },
        {
          'id': 'other_unacceptable',
          'title': 'Other Unacceptable (unlikely to be able to resubmit)',
          'text': 'Sorry but your task is not suitable for AnyTask and is against the Terms and Conditions.'
        },
        {
          'id': 'broken_image',
          'title': 'Broken image',
          'text': 'Sorry, we can see that your image is broken on your task.  Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'selling_cryptocurrencies',
          'title': 'Selling ETN or Cryptocurrencies',
          'text': 'Sorry, you cannot sell cryptocurrencies as a task.  It is against the Terms and Conditions.'
        },
        {
          'id': 'contact_details',
          'title': 'Contact Details in Description.',
          'text': 'Please do not include contact details in your task description, buyers can contact you through the AnyTask messaging system. Communicating outside of the platform will negate AnyTaskProtection™.'
        },
        {
          'id': 'social_media_likes',
          'title': 'Social media likes.',
          'text': 'Unfortunately, we are unable to list tasks guaranteeing social media likes and follows.'
        },
        {
          'id': 'remote_computer_support',
          'title': 'Remote computer support',
          'text': 'Unfortunately, we are unable to accept tasks providing remote computer support.'
        },
        {
          'id': 'inappropriate_images',
          'title': 'Inappropriate Images',
          'text': 'Kindly replace your task images;  some images are not allowed. Feel free to edit and resubmit your task.'
        },
        {
          'id': 'photo_not_compatible_with_your_description',
          'title': 'Photo Not Compatible with your Description',
          'text': 'Sorry, we can see that your task description is not compatible with your task image display. Please amend and resubmit your task.'
        },
        {
          'id': 'copyright_photo',
          'title': 'Copyright Photo',
          'text': 'Please remove or replace the web-sourced image from your task. You can make changes and resubmit. Thank you for understanding and following our requirements.'
        },
        {
          'id': 'link_in_description',
          'title': 'Link in Description',
          'text': 'Sorry, we can see that you have included a personal link in your task description. Please remove this and resubmit your task for review.'
        },
        {
          'id': 'inappropriate_photo',
          'title': 'Inappropriate Photo',
          'text': 'Sorry, we can see that one of your images is inappropriate and not compatible with the Terms and Conditions. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'contact_details_in_task_photo',
          'title': 'Contact Details in Task Photo',
          'text': 'Sorry, we can see that your Task Photo contains visible contact numbers / links details / email addresses and is not suitable for approval and does not meet our Terms & Conditions. Kindly remove the details and resubmit.'
        },
        {
          'id': 'different_prices',
          'title': 'Different Prices',
          'text': 'Sorry, we can see that you have different pricing offered from your Task description. Please create a separate Task for each package or additional cost offered.'
        },
        {
          'id': 'task_title_not_capitalized',
          'title': 'Task Title Not Capitalized',
          'text': 'Please use capital letters at the beginning of each word in your Task Title. Feel free to amend and resubmit your Task.'
        },
        {
          'id': 'promoting_different_platform_1',
          'title': 'Promoting Different Platform option 1',
          'text': 'Sorry, but we\'ve noticed that your task description appears to promote another platform, this is not acceptable. Please feel free to make the necessary adjustments and resubmit your task for review.'
        },
        {
          'id': 'promoting_different_platform_2',
          'title': 'Promoting Different Platform option 2',
          'text': 'Sorry, but we\'ve noticed that your task description appears to promote another platform, this is not acceptable. Please feel free to make the necessary adjustments and resubmit your task for review.'
        },
        {
          'id': 'image_taken_from_the_web',
          'title': 'Image Taken from the Web',
          'text': 'Sorry, we can see that your image has been taken from the web. Please feel free to amend and resubmit your task. You can make amend this and resubmit. Thank you for understanding and cooperating with our guidelines.'
        },
        {
          'id': 'header_not_suitable_to_the_description',
          'title': 'Header Not Suitable To The Description',
          'text': 'Sorry, we can see that your Task Header is not related to your task description. Please feel free to amend and resubmit your task.'
        },
        {
          'id': 'typo_in_header',
          'title': 'Typo In Header',
          'text': 'Sorry, we can see that there is an error in your Task Header. Please feel free to amend and resubmit your task.'
        }
      ]
    }
  },
  methods: {
    resetModal () {
      this.submitted = false
      this.loading = false
      this.errors = []
      this.errorMessagesApi = []
      this.formData = {
        reason: '',
        status: null
      }
    },
    updateDeclineMessage (text) {
      document.getElementById('taskDecision').textContent = text
    },
    decision () {
      this.loading = true
      this.errorMessagesApi = []
      document.getElementById('taskWarning').style.display = 'none'
      document.getElementById('taskWarningApi').style.display = 'none'
      let reasonValue = document.getElementById('taskDecision').value

      if (reasonValue.length === 0) {
        document.getElementById('taskWarning').style.display = 'block'
        this.loading = false
      } else {
        let data = {
          'decision': 0,
          'reason': reasonValue
        }

        APIService.adminApproveTask(this.hash, data).then(() => {
          document.getElementById('taskUpdateSuccess').style.display = 'block'
          this.$bvModal.hide('DeclineModal')
          this.$parent.$parent.updatePagination()
        }).catch((error) => {
          let errors = []
          if (error.response.data.code === 'HTTP-400') {
            if (error.response.data.errors.children.reason.errors) {
              document.getElementById('taskWarningApi').style.display = 'block'
              errors.push(error.response.data.errors.children.reason.errors[0])
            }
            if (error.response.data.errors.children.decision.errors) {
              document.getElementById('taskWarningApi').style.display = 'block'
              errors.push(error.response.data.errors.children.decision.errors[0])
            }
            this.errorMessagesApi = errors
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
