<template>
  <div class="container mb-3 mt-3">
    <table class="table table-striped table-bordered add-manage-table"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr>
        <th class="d-none d-sm-table-cell">{{$t('username')}}</th>
        <th>{{$t('actioned_by')}}</th>
        <th>{{$t('task_advice_title_title')}}</th>
        <th>{{$t('categories')}}</th>
        <th>Orders Accepted</th>
        <th>{{$t('date_created')}}</th>
        <th>{{$t('date_modified')}}</th>
        <th>User Shadow Banned</th>
        <th v-if="roleCheckEdit">Action</th>
      </tr>
      </thead>
      <tbody class="mb-md-0 mb-3 text-left">
      <tr v-for="(task, index) in tasks" :key="index" class="cursor-pointer">
        <td @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Username" class="d-none d-sm-table-cell"><i class="fa-solid fa-magnifying-glass mr-2" aria-hidden="true"/>{{task[0].user.username}}</td>
        <td @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="ActionedBy">{{ task[0].decisions[0] !== undefined ? task[0].decisions[0].user.username : ''}}</td>
        <td @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Task Title">{{task[0].title}}</td>
        <td @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" :data-title="$t('categories')">
          <ul class="mb-0 ml-1 pl-2">
            <li v-for="(category,index) in task[0].categories" v-bind:key="index">
              {{category.parent_category.name}}
              &raquo;
              {{ category.name }}
            </li>
          </ul>
        </td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Accepted Orders / Total Orders">{{ task.orders_accepted && task.orders_total ? task.orders_accepted +  ' / ' + task.orders_total : 'No data' }}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Date Created">{{date(task[0].date_created)}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Date modified">{{date(task[0].date_modified)}}</td>
        <td class="text-center" @click="$router.push('/' + task[0].user.username + '/' + task[0].slug)" data-title="Shadow ban">{{ task[0].user.shadow_ban }}</td>
        <td v-if="roleCheckEdit">
          <b-button v-if="roleCheckEdit" class="mb-3 w-100" variant="primary" @click="$router.push('/admin/tasks/' + task[0].user.username + '/' + task[0].slug + '/edit')">Edit Task</b-button>
          <b-button v-if="roleCheckEdit"  variant="danger" @click="declineTask(task[0].hash)" block >Deactivate Task</b-button>
        </td>
      </tr>
      </tbody>
    </table>
    <DeclineTaskPopUp :hash="selectedHash"/>
  </div>
</template>

<script>
import DeclineTaskPopUp from './AdminDeactivateTaskPopUp'

export default {
  name: 'AdminAcceptedTaskTable',
  props: {
    tasks: Array
  },
  components: {
    DeclineTaskPopUp
  },
  data () {
    return {
      selectedHash: '',
      roleCheckEdit: this.$store.getters.hasRole('ROLE_ADMIN_TASK_LIVE_EDIT')
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return 'Currently not modified'
      }
      const newDate = new Date(date)
      const todayDate = new Date()

      if (newDate.setHours(0, 0, 0, 0) === todayDate.setHours(0, 0, 0, 0)) {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      } else {
        return newDate.toLocaleDateString()
      }
    },
    declineTask (hash) {
      document.getElementById('taskUpdateSuccess').style.display = 'none'
      this.selectedHash = hash
      this.$bvModal.show('DeclineModal')
    }
  }
}
</script>

<style scoped>
  ul li {
    list-style: disc;
  }
</style>
